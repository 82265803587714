import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Page } from "../components";

const TOSPage: FunctionComponent = (): JSX.Element => (
  <Page title="Terms of Service" layout="default">
    <div className="tile--two-thirds">
      <Heading level={1} style={{ textAlign: "center" }}>
        Terms of Service
      </Heading>
      <div className="tile info-section">
        <p>
          {`TERMS OF SERVICE – Hawaiiana Management Company, Ltd. You ("you" or
          the "User") are about to register to view a web site hosted by, and/or
          use other services provided or arranged by Hawaiiana Management
          Company, Ltd. ("we," "us" or HAWAIIANA). We provide to associations
          and their members online resources and services (collectively, the
          "Services"), subject to the following terms and conditions and any
          rules, guidelines or policies that may be published from time to time
          by us (collectively, the "Terms of Service"). We may refuse to provide
          the Services to any person or entity in our sole discretion. We may
          cancel the Services with or without cause at any time, including,
          without limitation, if any of the registration information you
          provided is false or misleading, if you have violated these Terms of
          Service, or if your association or its management company informs us
          that you are not authorized to use any of the Services. Unless we
          state otherwise, any changes or additions to any of the Services, will
          be subject to these Terms of Service. These Terms of Service
          (including the rules, guidelines and policies incorporated herein) may
          be changed from time to time by us without notice. By registering to
          use or using this web site or any of the other Services, you agree to
          these Terms of Service. You should read them carefully and check for
          the most current version at www.frontsteps.com/terms-of-service`}
        </p>
        <p>
          {`We do not provide hardware, software, equipment or systems to any User
          to connect to or access the Internet, and all such hardware, software,
          equipment and systems shall remain the sole responsibility of the
          User.`}
        </p>
        <p>{`Terms of Service Last Revised: May 1, 2019.`}</p>
        <p>
          {`1. REGISTRATION AND PASSWORDS By registering to use the Services, you
          represent to us and agree that (a) you are 18 years of age or older,
          (b) a member in good standing of the association whose web site or
          other Services you intend to use and/or are otherwise permitted to
          access the web site or use the other Services for which you are
          registering, and (c) you have provided to us and will maintain at all
          times with us accurate, correct and complete information that may be
          requested by the registration forms (the "Registration Data"). If we
          have reason to believe that the Registration Data you have provided is
          not accurate or incomplete, we may suspend or terminate your ability
          to use any or all of the Services. Your Registration Data is subject
          to our Online Privacy Policy (see Section 3). We attempt to protect
          the privacy and integrity of the Services by making access to and use
          of the Services subject to the use of a password. When you register to
          use the Services the first time, you will be given initial access to
          the Services through a password. Thereafter, you must use your
          password to access and use the Services. You must keep your password
          confidential and not share your password with anyone. You will be
          fully responsible for all activity that occurs under the use of your
          user name or password. You agree to immediately notify us of any
          unauthorized use of your user name or password by emailing us at
          OnlineSupport@hmcmgt.com.`}
        </p>
        <p>
          {`2. CONDITIONS OF USE You agree that you may use the Services only as
          long as you comply with rules, regulations and guidelines ("Rules")
          published by us from time to time. The Rules are in addition to any
          other rules, regulations or guidelines that may be adopted from time
          to time by your association or its management company. The current
          Rules are published at www.frontsteps.com/rules-and-regulation We may
          amend or supplement the Rules from time to time in our sole discretion
          without prior notice. Changes are effective upon posting, and we
          encourage you to frequently review the Rules online for any changes.
          The Rules, as amended and supplemented from time to time, are
          incorporated by reference into these Terms of Service. In accordance
          with Section 4 of these Terms of Service, you agree not to reproduce,
          duplicate, copy, sell, resell or exploit for any commercial purposes,
          any portion of the Services. We do not in the ordinary course
          pre-screen or monitor content of the web sites or public areas, nor
          on-line postings or other communications. However, we reserve the
          right, but not the obligation, to determine in our sole discretion
          what is and is not acceptable content on any web site or in any other
          communication or posting made in connection with the Services, to
          limit placement of any content on a web site or on any other area
          provided in connection with the Services, to remove, alter or block
          access to any content immediately and without prior notice, to
          determine in our sole and absolute discretion what is and is not an
          appropriate conduct and use of any of the Services, and to cease
          providing or bar access to any or all of the Services to any User at
          any time, for any reason or for no reason, without prior notice. We
          reserve the right, but not the obligation, to monitor and investigate
          complaints regarding any of the foregoing, and you agree to cooperate
          fully with us in providing access and information as may be requested
          at any time and from time to time. You agree that any reservation of
          rights by us imposes no obligation of any kind on us to take any of
          the foregoing actions. Access to an association's web site and other
          portions of the Services, including content, is password protected.
          Currently, we also offer web site content backup Services to assist in
          the protection of content from loss. However, we do not warrant or
          guarantee the integrity or security of the Services or the content,
          information or data transmitted through or contained on any portion of
          the Services. Ultimately, the security of any association's web site,
          and of access to and of all content and data located on, any web site,
          are the responsibility of each association, its management company
          and/or the members of the association. Content and data on any web
          site may be subject to loss, alteration, corruption or destruction,
          and we shall have no responsibility therefore.`}
        </p>
        <p>
          {`3. PRIVACY Registration Data and other personally identifiable
          information about you that you provide to us through the Services is
          subject to our Online Privacy Policy. Our current Online Privacy
          Policy is found at www.hmcmgt.com/privacy-policy. Our Online Privacy
          Policy may be amended or supplemented from time to time by us in our
          sole discretion without prior notice. Changes are effective upon
          posting, and we encourage you to frequently review the Online Privacy
          Policy for any changes.`}
        </p>
        <p>
          {`4. PROPRIETARY RIGHTS We and/or our licensors own (a) the contents of
          the HAWAIIANA web site, (b) the content contained in or presented
          through the Services by us or by third parties engaged by us
          (including, without limitation, text, music, sound, photographs,
          graphics, video, page layout, and design), (c) the software, hardware,
          files, processes, systems, databases and tools used or provided by us
          or by third parties engaged by us to provide the Services, (d) other
          tangible and intangible personal property relating to the Services,
          including, without limitation, the domain names, IP numbers and
          addresses that may be used by us in providing the Services, and (e)
          the trade names, trade marks, service marks, copyrights, patents,
          inventions, trade secrets, know-how and other intellectual property
          rights relating to the foregoing ("HAWAIIANA Property"). These Terms
          of Service do not constitute a license to you or any other person to
          use any HAWAIIANA Property, except that you may use those Services
          that we make available to you solely for your personal use. You agree
          that you will not, and that you will not permit any person or entity
          to, copy, revise, alter, modify, decompile, reverse engineer,
          assemble, or attempt to discover, nor sell, assign, sublicense,
          encumber, or otherwise transfer any interest in, any HAWAIIANA
          Property, including, without limitation, any object code, source code,
          underlying processes or algorithms contained therein, other than as is
          permitted by us in writing. However, you may download a single copy of
          this web site onto a single computer or make one print copy for your
          personal, noncommercial use. Your association or its management
          company may permit you to submit additional content to a web site to
          any "public areas" provided as part of the Services, subject to these
          Terms of Service (including, without limitation, the Rules). "Public
          areas" are areas where you may submit content for viewing by others
          and where others may submit content for viewing by you, such as news
          items, chat rooms or bulletin boards, whether or not access to such
          areas is restricted. You are responsible for such content and for
          postings, email and other communications transmitted or posted by you
          using the Services. By creating, posting or submitting content on a
          web site or any public area, or permitting others to do the same, you
          represent and warrant that the content complies with these Terms of
          Service (including, without limitation, the Rules), and grant to us
          and our affiliates a worldwide, perpetual, irrevocable, royalty-free,
          sub licensable (through multiple tiers) non-exclusive right and
          license to exercise all rights with respect to the content submitted,
          created or posted in any public area, and to cache, backup and
          otherwise use all such content, in order to provide the Services. You
          agree that such caching, backup and other use is not an infringement
          of any of your intellectual property rights or any third party's
          intellectual property rights. You agree that we may preserve or
          disclose content if required to do so by law or regulation or in the
          good faith belief that disclosure is reasonably necessary to: (i)
          comply with legal process; (ii) enforce the Terms of Service; (iii)
          respond to claims that any content violates the rights of third
          parties; (iv) protect the rights, property, or personal safety of us,
          other Users or the public; or (v) provide the Services.
        </p>
        <p>
          5. THIRD PARTY DEALINGS If you acquire goods or services from a third
          party, whether or not the goods or services are used as part of or
          found through the Services, you agree that your business dealings with
          that third party are solely between you and the third party. We have
          no liability for any reason connected with such third party or the
          goods or services acquired, including, without limitation, complaints
          or claims concerning failure to perform, defects in goods or services
          or otherwise. Links to other web sites or resources may be provided by
          us or third parties as part of the Services. You agree that we have no
          control over such sites and are not responsible for them. You also
          agree that we are not responsible for any content, advertising,
          products or material on or available from such sites or resources.
          Please see our Privacy Policy for information on these third party
          links and your consent to the use of third party providers.`}
        </p>
        <p>
          {`6. DISCLAIMERS AND LIMITATIONS USE OF THE SERVICES AND ANY EQUIPMENT,
          SOFTWARE AND HARDWARE PROVIDED IN CONNECTION WITH THE SERVICES, IS ON
          AN "AS IS" BASIS AND ON AN "AS AVAILABLE" BASIS. WE MAKE NO, AND
          HEREBY DISCLAIM ANY AND ALL, REPRESENTATIONS AND WARRANTIES, EXPRESS
          OR IMPLIED, OF ANY KIND OR NATURE WITH RESPECT TO THE SERVICES OR SUCH
          PROPERTY, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. NEITHER WE, YOUR ASSOCIATION, THE ASSOCIATION'S
          MANAGEMENT COMPANY, NOR ANY OTHER PERSON OR ENTITY INVOLVED IN
          CREATING, PRODUCING OR DELIVERING ANY OF THE SERVICES REPRESENTS OR
          WARRANTS THAT THE SERVICES WILL BE TIMELY, UNINTERRUPTED OR ERROR
          FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE
          SERVERS OR OTHER PROPERTY THAT ARE USED IN PROVIDING THE SERVICES WILL
          BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE HEREBY DISCLAIM ANY
          LIABILITY OR RESPONSIBILITY, ARISING OUT OF THE INACCURACY,
          ILLEGALITY, AND/OR INAPPROPRIATENESS OF ANY CONTENT PROVIDED TO ANY
          WEB SITE OR ANY PUBLIC AREA, THE DAMAGE, DESTRUCTION OR CORRUPTION OF
          ANY CONTENT OR OTHER DATA, OR THE USE OR MISUSE OF, OR INABILITY TO
          USE, THE SERVICES BY ANY PERSON OR ENTITY. IN NO EVENT WILL WE NOR ANY
          OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DELIVERING
          ANY OF THE SERVICES BE LIABLE FOR ANY INDIRECT, SPECIAL, OR
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
          DAMAGES FOR LOST PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE
          LOSSES (EVEN IF WE HAVE BEEN NOTIFIED OF THE POSSIBILITY OF SUCH
          LOSSES) ARISING OUT OF OR RELATED TO THE SERVICES. SOME JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR
          EXCLUSION OF LIABILITY. IN SUCH JURISDICTIONS, SOME OF THE LIMITATIONS
          IN THIS SECTION 8 MAY NOT APPLY TO YOU. Under no circumstances will we
          be liable for failure or delay in connection with the Services if the
          failure or delay is due to circumstances beyond our control including,
          without limitation, acts of any governmental body, war, insurrection,
          sabotage, embargo, fire, flood, strike or other labor disturbance,
          interruption of or delay in transportation, unavailability of,
          interruption or delay in telecommunication or third party services
          (including DNS propagation), failure of third party software or
          hardware, or inability to obtain raw materials, supplies, or power
          used in equipment needed for the provision of services. You agree that
          any claim or cause of action which you may have arising out of a claim
          related to these Terms of Service or the Services must be filed within
          one (1) year after such claim or cause of action arises, or the claim
          or cause of action will forever be barred.`}
        </p>
        <p>
          {` 7. INDEMNIFICATION You agree to indemnify and hold harmless us and our
          parents, subsidiaries, affiliates, officers, members, employees and
          representatives from any and all claims, liability and expenses
          (including without limitation, reasonable attorneys fees) arising out
          of or related to your use of the Services, your breach of any
          provision of these Terms of Service, or any content posted or
          transmitted by you through the use of the Services. We reserve the
          right, at our own expense, to assume the exclusive defense and control
          of any matter otherwise subject to indemnification by you, which shall
          not excuse your indemnity obligations.`}
        </p>
        <p>
          {`8. NOTICES We may send notices by electronic mail, regular mail,
          courier or overnight delivery to the electronic mail address, mailing
          address or delivery address most recently provided. Notices will be
          effective upon transmission or delivery. We may provide changes to the
          Terms of Service (including, without limitation, the Rules and our
          Online Privacy Policy) by posting such changes on the Internet.`}
        </p>
        <p>
          {`9. COPYRIGHTS AND INFRINGEMENT CLAIMS We respect the intellectual
          property rights of others. We will process and investigate notices of
          alleged infringement of intellectual property rights related to this
          web site or the Services, as provided by Digital Millennium Copyright
          Act ("DMCA"), and will respond appropriately, as provided by the DMCA.
          As appropriate, we will move expeditiously to remove or disable access
          to material claimed to be infringing or claimed to be the subject of
          infringing activity. We will terminate access to Users who are repeat
          infringers. Notices of claimed infringement should be directed to our
          Copyright Agent at the following address:`}
        </p>
        <p>
          Legal Department Hawaiiana Group Incorporated <br />
          711 Kapiolani Boulevard, Suite 700 <br />
          Honolulu, HI 96813 <br />
          OnlineSupport@hmcmgt.com <br />
        </p>
        <p>
          {`10. GENERAL PROVISIONS These Terms of Service constitute the entire
          agreement between you and us concerning your use of the Services and
          the relationship between you and us, and supersede any prior or
          contemporaneous oral or written communications, representations or
          understandings concerning the subject matter. You may be subject to
          additional terms and conditions imposed by a separate agreement
          between Users, for example a separate agreement between an association
          and its members. We are not bound by or subject to any such
          agreements. In addition, we may have entered into separate agreements
          with less than all Users, for example an agreement between us and a
          management company. If you are not a party to any such agreement, then
          you agree that you are not subject to it, are not entitled to enforce
          it, and are not a third party beneficiary of it. You agree that you
          are not relying on any representation, warranty, guarantee or
          statement of any kind or nature made by us or anyone on our behalf
          except as set forth in these Terms of Service. If any provision of the
          Terms of Service is held invalid or unenforceable, the remaining
          provisions will remain in effect. No waiver of any breach or failure
          or delay in exercising any right, power or remedy of any provision of
          these Terms of Service shall constitute a waiver of the same or any
          other provision hereof with respect to prior, concurrent or subsequent
          occurrences and no waiver shall be effective unless made in writing
          and signed by an authorized representative of the party against whom
          such waiver is sought. These Terms of Service, and any dispute arising
          pursuant to these Terms of Service, shall be governed by Hawaii law,
          exclusive of its provisions regarding conflicts of law. Any action
          relating to these Terms of Service must be brought in Honolulu,
          Hawaii, and both parties irrevocably consent to the jurisdiction of
          the state and federal courts located in Honolulu, Hawaii. Titles and
          headings are included solely for convenient reference and are not part
          of these Terms of Service. You may not assign any of your rights or
          obligations (in whole or in part) without our prior written consent,
          which we may withhold, in our sole discretion. We may assign our
          rights and obligations under these Terms of Service without your prior
          written consent.`}
        </p>
        <p>
          {`11. MICROSOFT TERMS OF SERVICE We have incorporated certain third
          party services provided by Microsoft Corporation into the Services and
          your use of such services are conditioned upon your agreement to
          comply with the terms of use published by Microsoft Corporation, as
          these terms are modified from time to time in Microsoft’s sole and
          absolute discretion. A current copy of the terms of use are available
          at http://go.microsoft.com/fwlink/?Linkid=21969 (the “Microsoft Terms
          of Use”). You are responsible for reading carefully the Microsoft
          terms of use and complying with these conditions with regard to the
          third party services provided by Microsoft.`}
        </p>
      </div>
    </div>
  </Page>
);

export default TOSPage;
